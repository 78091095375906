import React, { useState, useEffect, useContext } from "react";
import SearchAppBar from "../../SearchAppBar";
import { Grid, useMediaQuery } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import AgentInfoPage from "./agentsComponents/agentInfoPage";
import CarrierHierarchies from "./agentsComponents/CarrierHierarchies";
import CurrentProduction from "./agentsComponents/CurrentProduction";
import ContractRequestPage from "../../../common/contractRequestPage";
import useStyles from "../../../common/useStyles";
import {
  agentProductionPortal,
  expandedAgentProductionPortal,
  certsPerAgent,
  getCurrentProductionYear,
} from "../../../services/BDSWebService";
import { reduceDate, validEmail } from "../../../services/utils";
import { useAuth } from "../../useAuth";
import { observer } from "mobx-react";
import { AgentStore, ContactStore, RootStoreContext } from "../../../store";
import UtilityLoading from "../../../common/UtilityLoading";
import ChangeRequest from "./agentsComponents/ChangeRequest";
import "../Home.css";
import BottomProfileBar from "./agentsComponents/BottomProfileBar";
import AlertModal from "../../../common/AlertModal";
import ContractRequestModal from "../../../common/ContractRequestModal";
import Swal from "sweetalert2";
import FeedbackModal from "../../../common/FeedbackModal";

const defaultCRValues = {
  agentId: "",
  nickName: "",
  email: "",
  phone: "",
  mobilePhone: "",
  workPhone: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  postalCode: "",
  birthDate: null,
  residentLicenseState: "",
  residentLicenseNumber: "",
  sex: "",
  suffix: "",
  npn: "",
};

const isStaging = sessionStorage.getItem("isStaging") === "true";

const AgentProfile = observer((props) => {
  useAuth();
  const pageFromHome = props.location.state?.pageFromHome;
  const { authStore } = useContext(RootStoreContext);
  const [contactStore] = useState(() => new ContactStore());
  const [agentStore] = useState(() => new AgentStore());
  const classes = useStyles();
  const [agentProduction, setAgentProduction] = useState([]);
  const [pageValue, setPageValue] = useState(pageFromHome ?? 0);
  const [allCarrierSalesData, setAllCarrierSalesData] = useState([]);
  const [certsData, setCertsData] = useState([]);
  const [certsLoading, setCertsLoading] = useState(false);
  const [openVert, setOpenVert] = useState(null);
  const [changeRequestOpen, setChangeRequestOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [changeRequestFormValues, setChangeRequestFormValues] =
    useState(defaultCRValues);
  const [initRequestFormValues, setInitRequestFormValues] =
    useState(defaultCRValues);
  const [year, setYears] = useState([]);

  const [productionPageValue, setProductionPageValue] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [contractRequestFormOpen, setContractRequestFormOpen] = useState(false);
  const [agentInfoSubmitted, setAgentInfoSubmitted] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);

  const handleOpenContractRequestForm = (val) => {
    setContractRequestFormOpen(val);
  };

  //Unfinished - errors on update only needed if we uncomment in request modal
  const updateFromContractRequest = (agentInfo) => {
    if (
      agentInfo.birthDate !==
      new Date(agentStore.agent.birthDate).toISOString().split("T")[0]
    ) {
      agentStore.updateAgent({ ...agentInfo }, agentStore.agent.id);
    }
  };

  useEffect(() => {
    return () => {
      agentStore.disposePersist();
      contactStore.disposePersist();
    };
  }, [agentStore, contactStore]);

  useEffect(() => {
    const getYearsFromApi = async () => {
      const prodYears = await getCurrentProductionYear();
      setYears([prodYears, prodYears - 1, prodYears - 2]);
    };

    if (agentStore.agent && agentStore.agent.id) {
      setCertsLoading(true);
      certsPerAgent(agentStore.agent.id).then((data) => {
        const certsWithReducedDate = data.data.certsPerAgent.map((r) => {
          r.recordDate = reduceDate(r.recordDate);
          return r;
        });
        setCertsData(certsWithReducedDate);
        setCertsLoading(false);
      });
      agentProductionPortal(agentStore.agent.id).then((data) =>
        setAgentProduction(data.data.agentProductionPortal)
      );
      expandedAgentProductionPortal(agentStore.agent.id).then((data) => {
        const reducedDate = data.data.expandedAgentProductionPortal.map(
          (data) => {
            data.effectivedate = reduceDate(data.effectivedate);
            return data;
          }
        );
        setAllCarrierSalesData(reducedDate);
      });
      getYearsFromApi();
    }
  }, [agentStore, agentStore.agent]);

  useEffect(() => {
    if (changeRequestOpen) {
      const {
        email,
        phone,
        mobilePhone,
        workPhone,
        address1,
        address2,
        address3,
        city,
        state,
        postalCode,
      } = contactStore.contacts;
      const {
        id,
        nickName,
        birthDate,
        residentState,
        residentLicenseNumber,
        npn,
        sex,
        suffix,
      } = agentStore.agent;
      const initValues = {
        agentId: id,
        nickName: nickName,
        email,
        phone,
        mobilePhone,
        workPhone,
        address1,
        address2,
        address3,
        city,
        state,
        postalCode,
        birthDate: birthDate ? birthDate : null,
        residentLicenseState: residentState ?? "",
        residentLicenseNumber,
        npn,
        sex,
        suffix,
      };
      setChangeRequestFormValues(initValues);
      setInitRequestFormValues(initValues);
    }
    return () => {
      setChangeRequestFormValues(defaultCRValues);
      setInitRequestFormValues(defaultCRValues);
    };
  }, [changeRequestOpen]);

  const handlePageChange = (e, newValue) => {
    setPageValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setPageValue(index);
  };

  const handleProductionPageChange = (e, newValue) => {
    setProductionPageValue(newValue);
  };
  const openVertMenu = (e) => {
    setOpenVert(e.currentTarget);
  };
  const closeVertMenu = () => {
    setOpenVert(false);
  };

  const backToHome = () => {
    const {
      history: { push },
    } = props;
    push("/Home");
  };

  useEffect(() => {
    setTimeout(() => {
      if (agentStore.agent === null) {
        authStore.logout();
      }
    }, 2000);
  }, [agentStore.agent, authStore]);

  const openDialog = (type) => {
    type === "cr" ? setChangeRequestOpen(true) : setNotificationsOpen(true);
  };

  const closeDialog = (type) => {
    type === "cr" ? setChangeRequestOpen(false) : setNotificationsOpen(false);
  };

  const submitChangeRequest = () => {
    //filter empty values and reduce - remove agentId to use later
    const { agentId, ...rest } = Object.entries(changeRequestFormValues)
      .filter(([key, value]) => value && value.trim() !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    //Check for any changes and remove undefined objects
    const changes = Object.entries(rest)
      .map(([key1, value1]) => {
        if (
          !Object.entries(initRequestFormValues).some(
            ([key2, value2]) => key2 === key1 && value1 === value2
          )
        ) {
          return { [key1]: value1 };
        }
      })
      .filter((data) => data !== undefined);
    // Flatten all objects to one
    const result = Object.assign({}, ...changes);
    if (result.email && result.email !== "" && !validEmail(result.email)) {
      setOpenAlert(true);
      setAlertMessage("Please enter a valid email");
    } else if (
      //Check empty object
      result &&
      Object.keys(result).length === 0 &&
      result.constructor === Object
    ) {
      setOpenAlert(true);
      setAlertMessage("No Changes have been made.");
    } else if (isStaging) {
      closeDialog("cr");
      Swal.fire({
        title: "Submitted for processing.",
        text: "Please note that is can take up to 72 hours for changes to be reflected",
        icon: "success",
      });
    } else {
      closeDialog("cr");
      try {
        agentStore.agentChangeRequest({
          agentId,
          firstName: agentStore.agent.firstName,
          lastName: agentStore.agent.lastName,
          ...result,
        });
        Swal.fire({
          title: "Submitted for processing.",
          text: "Please note that is can take up to 72 hours for changes to be reflected",
          icon: "success",
        });
      } catch (error) {
        Swal.fire({
          title: "Oops!",
          icon: "error",
          text: "There was an error with your submission, please try again.",
        });
      }
    }
  };

  const formChanges = (e, date, incName) => {
    // Special casing for datepicker
    const name = e?.target?.name ?? incName;
    const value = e?.target?.value
      ? e.target.value
      : date
      ? date.toLocaleString().split(",")[0]
      : null;
    const newObject = {
      ...changeRequestFormValues,
      [name]: value,
    };
    setChangeRequestFormValues(newObject);
    const check = Object.values(newObject).find((data) => data !== "");
    if (check !== undefined) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const closeAlert = () => {
    setOpenAlert(false);
  };

  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;

  const openFeedbackModal = () => {
    setOpenFeedback(true);
  };

  const closeFeedbackModal = () => {
    setOpenFeedback(false);
  };

  //Loader needed for a small ms lag time with store load of agent
  if (agentStore.agent === null || contactStore.contacts === null) {
    return (
      <UtilityLoading
        loadingState={
          agentStore.agent === null ? agentStore.loading : contactStore.loading
        }
      >
        Loading...
      </UtilityLoading>
    );
  }

  return (
    <div className="homePage">
      <SearchAppBar
        clickedAgent={agentStore.agent}
        openVertMenu={openVertMenu}
        closeVertMenu={closeVertMenu}
        openVert={openVert}
        backToHome={backToHome}
        openDialog={openDialog}
        openFeedbackModal={openFeedbackModal}
      />
      <Grid className={classes.paper}>
        <SwipeableViews index={pageValue} onChangeIndex={handleChangeIndex}>
          {pageValue === 0 && (
            <AgentInfoPage
              classes={classes}
              clickedAgent={agentStore.agent}
              openDialog={openDialog}
              contact={contactStore.contacts}
            />
          )}
          {pageValue === 1 && (
            <CarrierHierarchies clickedAgent={agentStore.agent} />
          )}
          {pageValue === 2 && (
            <CurrentProduction
              classes={classes}
              productionPageValue={productionPageValue}
              handleProductionPageChange={handleProductionPageChange}
              agentProduction={agentProduction}
              allCarrierSalesData={allCarrierSalesData}
              year={year}
            />
          )}
          {pageValue === 3 && (
            <ContractRequestPage
              classes={classes}
              clickedAgent={agentStore.agent}
              certsData={certsData}
              certsLoading={certsLoading}
              pendingRequests={agentStore.pendingRequests}
              agentStore={agentStore}
            />
          )}
        </SwipeableViews>
      </Grid>
      <BottomProfileBar
        classes={classes}
        pageValue={pageValue}
        setPageValue={setPageValue}
        handlePageChange={handlePageChange}
        handleOpenContractRequestForm={handleOpenContractRequestForm}
      />
      <ChangeRequest
        submitChangeRequest={submitChangeRequest}
        formChanges={formChanges}
        disabled={disabled}
        closeDialog={closeDialog}
        loading={agentStore.loading}
        changeRequestFormValues={changeRequestFormValues}
        mQuery={mQuery}
        changeRequestOpen={changeRequestOpen}
      />
      <AlertModal
        openAlert={openAlert}
        closeAlert={closeAlert}
        alertMessage={alertMessage}
      />
      <FeedbackModal
        openFeedback={openFeedback}
        closeFeedbackModal={closeFeedbackModal}
        agentId={agentStore.agent.id}
      />
      <ContractRequestModal
        openRequestForm={contractRequestFormOpen}
        agent={agentStore.agent}
        agentToh={agentStore.agentToh}
        handleOpenContractRequestForm={handleOpenContractRequestForm}
        agentInfoSubmitted={agentInfoSubmitted}
        setAgentInfoSubmitted={setAgentInfoSubmitted}
        updateFromContractRequest={updateFromContractRequest}
      />
    </div>
  );
});

export default AgentProfile;
