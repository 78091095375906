import {
  AppBar,
  Button,
  Grid,
  IconButton,
  MobileStepper,
  Toolbar,
  useTheme,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import AlertModal from "../../../common/AlertModal";
import useStyles from "../../../common/useStyles";
import {
  checkAgentNpn,
  isAgent,
  updateAgentPassword,
  updateLastLogin,
} from "../../../services/BDSWebService";
import { validEmail } from "../../../services/utils";
import { AgentStore, ContactStore, RootStoreContext } from "../../../store";
import NpnStep from "./NpnStep";
import SignUpStep from "./SignUpStep";
import UpdateAgentStep from "./UpdateAgentStep";
import IdCheckStep from "./IdCheckStep";
import { useHistory } from "react-router-dom";
import AWAYPageLoading from "../onboard/AWAYPageLoading";
import RegOnbAppBar from "./RegOnbAppBar";
const initState = {
  status: "",
  message: "Submit",
  background: "",
};

const defaultCRValues = {
  agentId: "",
  nickName: "",
  email: "",
  phone: "",
  mobilePhone: "",
  workPhone: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  postalCode: "",
  birthDate: null,
  residentLicenseState: "",
  residentLicenseNumber: "",
  sex: "",
  suffix: "",
  npn: "",
};

const isStaging = sessionStorage.getItem("isStaging") === "true";

const Registration = observer(() => {
  const classes = useStyles();
  const theme = useTheme();
  const [agentStore] = useState(() => new AgentStore());
  const [contactStore] = useState(() => new ContactStore());
  const { authStore } = useContext(RootStoreContext);
  const [activeStep, setActiveStep] = useState(0);
  const [npn, setNpn] = useState("");
  const [registrationId, setRegistrationId] = useState("");
  const [npnCheck, setNpnCheck] = useState(initState);
  const [registrationIdCheck, setRegistrationIdCheck] = useState(initState);
  const [changeRequestFormValues, setChangeRequestFormValues] =
    useState(defaultCRValues);
  const [initRequestFormValues, setInitRequestFormValues] =
    useState(defaultCRValues);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [stepTwoSuccess, setStepTwoSuccess] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [title, setTitle] = useState("Welcome To Agent Way Registration!");
  const [flip, setFlip] = useState(false);
  let history = useHistory();
  useEffect(() => {
    return () => {
      setNpnCheck(initState);
      setRegistrationIdCheck(initState);
      setNpn("");
      setRegistrationId("");
      setActiveStep(0);
      setChangeRequestFormValues(defaultCRValues);
      setInitRequestFormValues(defaultCRValues);
      setDisabled(false);
      setStepTwoSuccess("");
      agentStore.disposePersist();
      contactStore.disposePersist();
    };
  }, []);

  const npnChange = (e) => {
    const value = e.target.value;
    if (value === "" || value !== npn) {
      setNpnCheck(initState);
    }
    setNpn(value);
  };

  const registrationIdChange = (e) => {
    const value = e.target.value;
    if (value === "" || value !== registrationId) {
      setRegistrationIdCheck(initState);
    }
    setRegistrationId(value);
  };

  const next = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const previous = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkNpn = async () => {
    const isValidNpn = await checkAgentNpn(authStore.user.id, npn);
    const check = isValidNpn
      ? {
          status: "c",
          message: "Npn Verified",
          background: theme.palette.success.main,
        }
      : {
          status: "nc",
          message: "No Match, Try Again!",
          background: theme.palette.secondary.main,
        };
    setNpnCheck(check);
  };

  const checkRegistrationId = async () => {
    const isValidAgent = await isAgent(registrationId);
    const check = isValidAgent
      ? {
          status: "c",
          message: "Verified",
          background: theme.palette.success.main,
        }
      : {
          status: "nc",
          message: "No Match, Try Again!",
          background: theme.palette.secondary.main,
        };
    setRegistrationIdCheck(check);
  };

  useEffect(() => {
    if (npnCheck.status === "c") {
      agentStore.load(authStore.user.id).then((check) => {
        if (check) {
          contactStore.loadContacts(authStore.user.id).then(() => {
            const {
              email,
              phone,
              mobilePhone,
              workPhone,
              address1,
              address2,
              address3,
              city,
              state,
              postalCode,
            } = contactStore.contacts ? contactStore.contacts : {};
            const {
              id,
              nickName,
              birthDate,
              residentState,
              residentLicenseNumber,
              npn,
              sex,
              suffix,
            } = agentStore.agent;
            const initValues = {
              agentId: id,
              nickName: nickName,
              email,
              phone,
              mobilePhone,
              workPhone,
              address1,
              address2,
              address3,
              city,
              state,
              postalCode,
              birthDate: birthDate
                ? new Date(birthDate).toISOString().split("T")[0]
                : null,
              residentLicenseState: residentState,
              residentLicenseNumber,
              npn,
              sex,
              suffix,
            };
            setChangeRequestFormValues(initValues);
            setInitRequestFormValues(initValues);
          });
        } else {
          authStore.logout();
        }
      });
    }
  }, [npnCheck]);

  // AWAY-419: Commented out the following code block.  Not deleting as this step could be re-enabled in the future.
  // const submitChangeRequest = () => {
  //   //filter empty values and reduce - remove agentId to use later
  //   const { agentId, ...rest } = Object.entries(changeRequestFormValues)
  //     .filter(([key, value]) => value && value.trim() !== "")
  //     .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  //   //Check for any changes and remove undefined objects
  //   const changes = Object.entries(rest)
  //     .map(([key1, value1]) => {
  //       if (
  //         !Object.entries(initRequestFormValues).some(
  //           ([key2, value2]) => key2 === key1 && value1 === value2
  //         )
  //       ) {
  //         return { [key1]: value1 };
  //       }
  //     })
  //     .filter((data) => data !== undefined);
  //   // Flatten all objects to one
  //   const result = Object.assign({}, ...changes);
  //   if (result.email && result.email !== "" && !validEmail(result.email)) {
  //     setOpenAlert(true);
  //     setAlertMessage("Please enter a valid email");
  //   } else if (
  //     //Check empty object
  //     result &&
  //     Object.keys(result).length === 0 &&
  //     result.constructor === Object
  //   ) {
  //     setOpenAlert(true);
  //     setAlertMessage("No Changes have been made.");
  //   } else if (isStaging) {
  //     Swal.fire({
  //       title: "Submitted for processing.",
  //       text: "Please note that is can take up to 72 hours for changes to be reflected",
  //       icon: "success",
  //     });
  //     setStepTwoSuccess("Done");
  //   } else {
  //     try {
  //       agentStore.agentChangeRequest({
  //         agentId,
  //         firstName: agentStore.agent.firstName,
  //         lastName: agentStore.agent.lastName,
  //         ...result,
  //       });
  //       Swal.fire({
  //         title: "Submitted for processing.",
  //         text: "Please note that is can take up to 72 hours for changes to be reflected",
  //         icon: "success",
  //       });
  //       setStepTwoSuccess("Done");
  //     } catch (error) {
  //       Swal.fire({
  //         title: "Oops!",
  //         icon: "error",
  //         text: "There was an error with your submission, please try again.",
  //       });
  //     }
  //   }
  // };

  // AWAY-419: Commented out the following code block.  Not deleting as this step could be re-enabled in the future.
  // const formChanges = (e, date, incName) => {
  //   // Special casing for datepicker
  //   const name = e?.target?.name ?? incName;
  //   const value = e?.target?.value
  //     ? e.target.value
  //     : date
  //     ? date.toLocaleString().split(",")[0]
  //     : null;
  //   const newObject = {
  //     ...changeRequestFormValues,
  //     [name]: value,
  //   };
  //   setChangeRequestFormValues(newObject);
  //   const check = Object.values(newObject).find((data) => data !== "");
  //   if (check !== undefined) {
  //     setDisabled(false);
  //     setStepTwoSuccess("");
  //   } else {
  //     setDisabled(true);
  //   }
  // };

  const registerUser = async (isDemoAgent) => {
    isDemoAgent
      ? authStore.userLogin()
      : authStore.newUserLogin(registrationId, "agents", "");
  };

  const submitAndNav = async () => {
    const id = authStore.user.id ?? agentStore.agent.id;
    Swal.fire({
      title: "Registration Complete",
      icon: "success",
      text: "Registration is now complete! Please click the button below to enter Agent Way.",
      showCancelButton: false,
      confirmButtonText: "Let's Go!",
    }).then(() => {
      updateLastLogin(id);
      history.push("/Home");
    });
  };

  const steps = [
    {
      content: (
        <IdCheckStep
          registrationId={registrationId}
          registrationIdChange={registrationIdChange}
          registrationIdCheck={registrationIdCheck}
          theme={theme}
          checkRegistrationId={checkRegistrationId}
        />
      ),
    },
    {
      content: (
        <SignUpStep
          registerUser={registerUser}
          theme={theme}
          registrationId={registrationId}
          authStore={authStore}
        />
      ),
    },
    {
      content: (
        <NpnStep
          npn={npn}
          npnChange={npnChange}
          npnCheck={npnCheck}
          theme={theme}
          checkNpn={checkNpn}
        />
      ),
    },

    // AWAY-419: Commented out the following code block.  Not deleting as this step could be re-enabled in the future.
    // {
    //   content: (
    //     <UpdateAgentStep
    //       classes={classes}
    //       changeRequestFormValues={changeRequestFormValues}
    //       formChanges={formChanges}
    //       submitChangeRequest={submitChangeRequest}
    //       disabled={disabled}
    //       stepTwoSuccess={stepTwoSuccess}
    //       theme={theme}
    //       next={next}
    //     />
    //   ),
    // },
  ];

  const closeAlert = () => {
    setOpenAlert(false);
  };

  const disable =
    (activeStep === 0 && registrationIdCheck.status !== "c") ||
    (activeStep === 1 && !authStore.isLoggedIn) ||
    (activeStep === 2 && npnCheck.status !== "c");

  useEffect(() => {
    setPageLoading(true);
    let timer1 = setTimeout(() => {
      setTitle("Let's Get Started");
      setFlip(true);
    }, 2000);
    let timer2 = setTimeout(() => {
      setPageLoading(false);
      setTitle("");
    }, 4000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      setPageLoading(false);
      setFlip(false);
      setTitle("Welcome To Agent Way Registration!");
    };
  }, []);

  useEffect(() => {
    !isStaging &&
      sessionStorage.setItem(
        "isStaging",
        !window.location.href.includes("://agentway.io")
      );
  }, [isStaging]);

  if (pageLoading) {
    return <AWAYPageLoading flip={flip} title={title} />;
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: "100%" }}
    >
      <RegOnbAppBar appBarTitle="Registration" />
      {steps[activeStep].content}
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={4}>
              <MobileStepper
                style={{ background: "none" }}
                steps={steps.length}
                position="static"
                variant="progress"
                activeStep={activeStep}
                nextButton={
                  <Grid style={{ display: "flex", flexDirection: "row" }}>
                    {activeStep === 2 ? (
                      <Button
                        size="small"
                        onClick={() => submitAndNav()}
                        disabled={disable}
                      >
                        Finish
                      </Button>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={next}
                        disabled={disable}
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    )}
                  </Grid>
                }
                backButton={
                  <IconButton
                    size="small"
                    onClick={previous}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                }
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AlertModal
        openAlert={openAlert}
        closeAlert={closeAlert}
        alertMessage={alertMessage}
      />
    </Grid>
  );
});

export default Registration;
