import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import StepComplete from "./StepComplete";

const NpnStep = (props) => {
  const { npn, npnChange, npnCheck, theme, checkNpn } = props;
  return (
    <Grid item xs={12} container alignItems="center" justifyContent="center">
      {npnCheck.status === "c" ? (
        <StepComplete completeMessage="NPN verified! Click 'FINISH' below to move on." />
      ) : (
        <>
          <Grid item xs={12} align="center" style={{ marginBottom: 20 }}>
            <Typography variant="h6">
              Please Enter Your NPN Number Below
            </Typography>
            <Typography style={{ color: "#D4AF37" }} variant="caption">
              *Note: Your NPN must match to proceed with registration.
            </Typography>
          </Grid>
          <Grid item xs={12} align="center" style={{ marginBottom: 20 }}>
            <TextField
              variant="outlined"
              label="NPN"
              value={npn}
              onChange={(e) => npnChange(e)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{
                background:
                  npn === ""
                    ? "grey"
                    : npnCheck.status === "nc"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                color: "white",
              }}
              onClick={() => checkNpn()}
              disabled={npn === ""}
            >
              {npn !== "" ? npnCheck.message : "Please Enter Npn"}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default NpnStep;
