import { gql } from "apollo-boost";
import { fragments } from "./fragments";

export const searchLeadsAndAgents = gql`
  query searchLeadsAndAgents(
    $query: String!
    $type: SearchType
    $limit: Int
    $crmOwner: ID
    $languages: String
  ) {
    searchLeadsAndAgents(
      query: $query
      type: $type
      limit: $limit
      crmOwner: $crmOwner
      languages: $languages
    ) {
      __typename
      ... on Agent {
        id
        displayName
        mssEnrollmentDate
        mssStatus
        dashboardEnabled
        doNotContact
        contacts {
          data {
            email
            phone
            mobilePhone
            workPhone
            status
            type
            state
          }
        }
        crmOwner {
          displayName
        }
      }
      ... on Lead {
        id
        displayName
        leadDate
        priority
        residentState
        doNotContact
        contacts {
          data {
            email
            phone
            mobilePhone
            workPhone
            status
            type
          }
        }
        crmOwner {
          displayName
        }
      }
    }
  }
`;

export const agentProductionPortal = gql`
  query agentProductionPortal(
    $agentId: ID!
    $years: [String]
    $carriers: [ProductionCarrier]
  ) {
    agentProductionPortal(
      agentId: $agentId
      years: $years
      carriers: $carriers
    ) {
      agentId
      production {
        carrier
        year
        ma
        ms
        pdp
      }
    }
  }
`;

//grab extra carrier sales data
export const expandedAgentProductionPortal = gql`
  query expandedAgentProductionPortal($agentId: ID!) {
    expandedAgentProductionPortal(agentId: $agentId) {
      state
      county
      effectivedate
      maProduction
      medsuppProduction
      pdpProduction
      carrierName
      recordDate
    }
  }
`;

export const crmOwners = gql`
  query crmOwners($paging: PagingInput) {
    crmOwners(page: $paging) {
      data {
        id
        email
        firstName
        lastName
        status
        displayName
        recruiter
        username
        agentgroup
      }
    }
  }
`;

export const refreshGenericLogin = gql`
  query refreshGenericLogin(
    $username: String!
    $loginType: String!
    $refreshToken: String!
  ) {
    refreshGenericLogin(
      username: $username
      loginType: $loginType
      refreshToken: $refreshToken
    ) {
      token
      refreshToken
    }
  }
`;

export const getCarrierNameList = gql`
  query getCarrierNameList {
    getCarrierNameList {
      id
      name
    }
  }
`;

export const sendEmailApi = gql`
  mutation sendEmailApi(
    $from: String!
    $to: [String]!
    $cc: [String]
    $bcc: [String]
    # $replyTo: String
    $subject: String
    $body: String
    $attachments: [EmailAttachment]
    $save: Boolean
  ) {
    sendEmailApi(
      from: $from
      to: $to
      cc: $cc
      bcc: $bcc
      # replyTo: $replyTo
      subject: $subject
      body: $body
      attachments: $attachments
      save: $save
    ) {
      success
    }
  }
`;

export const createLead = gql`
  mutation createLead($info: LeadInfo!) {
    createLead(info: $info) {
      ...LeadData
      contacts {
        data {
          ...ContactData
        }
      }
      leadSources {
        data {
          ...LeadSourceData
        }
      }
      contractRequests {
        data {
          ...ContractRequestData
          carrier {
            id
            name
            status
          }
        }
      }
      crmOwner {
        ...AdminData
      }
    }
  }
  ${fragments.leadData}
  ${fragments.adminData}
  ${fragments.contactData}
  ${fragments.leadSourceData}
  ${fragments.contractRequestData}
`;

export const updateAgent = gql`
  mutation updateAgent($info: AgentInfo!, $id: ID!, $contactId: ID) {
    updateAgent(info: $info, id: $id, contactId: $contactId) {
      ...AgentData
      carrierHierarchiesExternal {
        totalCount
        data {
          ...CarrierHierarchyData
          agentHierarchyExternal {
            ...AgentHierarchyData
          }
        }
      }
      contracts {
        totalCount
        data {
          ...AgentContractData
        }
      }
      contacts {
        data {
          ...ContactData
        }
      }
      agentGroups {
        data {
          ...AgentGroupData
        }
      }
      crmOwner {
        ...AdminData
      }
    }
  }
  ${fragments.agentData}
  ${fragments.carrierHierarchyData}
  ${fragments.agentHierarchyData}
  ${fragments.agentContractData}
  ${fragments.adminData}
  ${fragments.contactData}
  ${fragments.agentGroupData}
`;

export const updateLead = gql`
  mutation updateLead($info: LeadInfo!, $id: ID!, $contactId: ID) {
    updateLead(info: $info, id: $id, contactId: $contactId) {
      ...LeadData
      contacts {
        data {
          ...ContactData
        }
      }
      leadSources {
        data {
          ...LeadSourceData
        }
      }
      contractRequests {
        data {
          ...ContractRequestData
          carrier {
            id
            name
            status
          }
        }
      }
      crmOwner {
        ...AdminData
      }
    }
  }
  ${fragments.leadData}
  ${fragments.adminData}
  ${fragments.contactData}
  ${fragments.leadSourceData}
  ${fragments.contractRequestData}
`;

export const getLeadSources = gql`
  query getLeadSources {
    getLeadSources {
      ...LeadSourceData
    }
  }
  ${fragments.leadSourceData}
`;

export const createNoteComment = gql`
  mutation createNoteComment($noteId: ID!, $info: NoteCommentInput!) {
    createNoteComment(noteId: $noteId, info: $info) {
      ...NoteData
      # lastModifiedBy {
      #   ...AdminData
      # }
      createdBy {
        ...AdminData
      }
      files {
        data {
          ...FileData
        }
      }
      comments {
        data {
          ...NoteCommentData
        }
      }
    }
  }
  ${fragments.noteData}
  ${fragments.adminData}
  ${fragments.fileData}
  ${fragments.noteCommentData}
`;

export const createNoteV2 = gql`
  mutation createNoteV2($info: CombinedNoteInput!) {
    createNoteV2(info: $info) {
      ...NoteData
      # lastModifiedBy {
      #   ...AdminData
      # }
      createdBy {
        ...AdminData
      }
      files {
        data {
          ...FileData
        }
      }
      comments {
        data {
          ...NoteCommentData
        }
      }
    }
  }
  ${fragments.noteData}
  ${fragments.adminData}
  ${fragments.fileData}
  ${fragments.noteCommentData}
`;

export const updateNoteV2 = gql`
  mutation updateNoteV2($id: ID!, $info: NoteInput!) {
    updateNoteV2(id: $id, info: $info) {
      ...NoteData
      # lastModifiedBy {
      #   ...AdminData
      # }
      createdBy {
        ...AdminData
      }
      files {
        data {
          ...FileData
        }
      }
      comments {
        data {
          ...NoteCommentData
        }
      }
    }
  }
  ${fragments.noteData}
  ${fragments.adminData}
  ${fragments.fileData}
  ${fragments.noteCommentData}
`;

export const saveContactAfterEdit = gql`
  mutation saveContactAfterEdit($info: ContactInput!) {
    saveContactAfterEdit(info: $info) {
      ...ContactData
    }
  }
  ${fragments.contactData}
`;

export const agent = gql`
  query agent($id: ID!) {
    agent(id: $id) {
      ...AgentData
      carrierHierarchiesExternal {
        totalCount
        data {
          ...CarrierHierarchyData
          agentHierarchyExternal {
            ...AgentHierarchyData
          }
        }
      }
      contracts {
        totalCount
        data {
          ...AgentContractData
        }
      }
      contacts {
        data {
          ...ContactData
        }
      }
      crmOwner {
        ...AdminData
      }
    }
  }
  ${fragments.agentContractData}
  ${fragments.agentHierarchyData}
  ${fragments.carrierHierarchyData}
  ${fragments.agentData}
  ${fragments.adminData}
  ${fragments.contactData}
`;

export const getAgency = gql`
  query getAgency {
    agencies {
      id
      agencyName
    }
  }
`;

export const ownerAgencies = gql`
  query ownerAgencies {
    ownerAgencies {
      agencyName
      agencyId
      id
    }
  }
`;

export const createContact = gql`
  mutation createContact($info: CreateContactInput!) {
    createContact(info: $info) {
      ...ContactData
    }
  }
  ${fragments.contactData}
`;

export const notes = gql`
  query notes($paging: PagingInput, $filters: [FilterInput]) {
    notes(paging: $paging, filters: $filters) {
      totalCount
      data {
        ...NoteData
        # lastModifiedBy {
        #   ...AdminData
        # }
        createdBy {
          ...AdminData
        }
        files {
          totalCount
          data {
            ...FileData
          }
        }
        comments {
          totalCount
          data {
            ...NoteCommentData
          }
        }
      }
    }
  }
  ${fragments.noteData}
  ${fragments.adminData}
  ${fragments.fileData}
  ${fragments.noteCommentData}
`;

export const lead = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      ...LeadData
      contacts {
        data {
          ...ContactData
        }
      }
      leadSources {
        data {
          ...LeadSourceData
        }
      }
      contractRequests {
        data {
          ...ContractRequestData
          carrier {
            id
            name
            status
          }
        }
      }
      crmOwner {
        ...AdminData
      }
    }
  }
  ${fragments.leadData}
  ${fragments.adminData}
  ${fragments.contactData}
  ${fragments.leadSourceData}
  ${fragments.contractRequestData}
`;

export const createLeadContractRequest = gql`
  mutation createLeadContractRequest($info: ContractRequestInput!) {
    createLeadContractRequest(info: $info) {
      ...ContractRequestData
      carrier {
        id
        name
        status
      }
    }
  }
  ${fragments.contractRequestData}
`;

export const contactsPerIndividualId = gql`
  query contactsPerIndividualId($individualId: String!) {
    contactsPerIndividualId(individualId: $individualId) {
      data {
        ...ContactData
      }
    }
  }
  ${fragments.contactData}
`;

export const ytdRecruitmentReport = gql`
  query ytdRecruitmentReport {
    ytdRecruitmentReport {
      rank
      agentOwner
      newAgentsYtd
    }
  }
`;

export const getNoteByNoteId = gql`
  query getNoteByNoteId($id: ID!) {
    getNoteByNoteId(id: $id) {
      ...NoteData
      # lastModifiedBy {
      #   ...AdminData
      # }
      createdBy {
        ...AdminData
      }
      files {
        data {
          ...FileData
        }
      }
      comments {
        data {
          ...NoteCommentData
        }
      }
    }
  }
  ${fragments.noteData}
  ${fragments.adminData}
  ${fragments.fileData}
  ${fragments.noteCommentData}
`;

export const getSignedUrl = gql`
  query getSignedUrl($id: ID!) {
    getSignedUrl(id: $id)
  }
`;

export const getLanguagesPerIndividualId = gql`
  query getLanguagesPerIndividualId($id: String!) {
    getLanguagesPerIndividualId(id: $id) {
      languages
    }
  }
`;

export const getLanguages = gql`
  query getLanguages {
    getLanguages {
      languages
    }
  }
`;

export const getCarriers = gql`
  query getCarriers {
    getCarriers {
      id
      name
      status
    }
  }
`;

export const agencyContractStatusReport = gql`
  query agencyContractStatusReport(
    $agencyId: ID!
    $carrierId: ID
    $status: String
    $state: String
  ) {
    agencyContractStatusReport(
      agencyId: $agencyId
      carrierId: $carrierId
      status: $status
      state: $state
    ) {
      carrierName
      agentId
      agentName
      phone
      email
      state
      status
      subStatus
      statusDate
      uplines
      isCorporation
      carrierLabel
      contractId
    }
  }
`;

export const resetAgentLoginDate = gql`
  query resetAgentLoginDate($agentId: String!) {
    resetAgentLoginDate(agentId: $agentId)
  }
`;

export const certsPerAgent = gql`
  query certsPerAgent($agentId: String!) {
    certsPerAgent(agentId: $agentId) {
      rtsCertsId
      contractId
      npn
      writingNumber
      isCertified
      isReady
      planYear
      carrierId
      isCorporation
      agentId
      name
      recordDate
      products {
        product
        certified
        state
        carrierNote
      }
    }
  }
`;

export const contractStatusTotals = gql`
  query contractStatusTotals(
    $carrierId: ID
    $contractStatus: String
    $agentOwnerId: ID
    $state: String
  ) {
    contractStatusTotals(
      carrierId: $carrierId
      contractStatus: $contractStatus
      agentOwnerId: $agentOwnerId
      state: $state
    ) {
      carrierName
      contractStatus
      statusCount
    }
  }
`;

export const contractStatusExcelContents = gql`
  query contractStatusExcelContents(
    $carrierId: ID
    $contractStatus: String
    $agentOwnerId: ID
    $state: String
  ) {
    contractStatusExcelContents(
      carrierId: $carrierId
      contractStatus: $contractStatus
      agentOwnerId: $agentOwnerId
      state: $state
    ) {
      carrierName
      agentName
      companyName
      npn
      phones
      email
      agentState
      agentCounty
      contractState
      contractStatus
      contractStatusDate
      effectiveDate
      readyToSell
      certified
      agentOwnerName
      isCorporation
      doNotContact
    }
  }
`;

export const agencyProductionByCarrier = gql`
  query agencyProductionByCarrier(
    $carrierId: ID!
    $salesYear: String!
    $agentOwnerId: ID
    $state: String
  ) {
    agencyProductionByCarrier(
      carrierId: $carrierId
      salesYear: $salesYear
      agentOwnerId: $agentOwnerId
      state: $state
    ) {
      agencyName
      highestLevel
      ma
      medsupp
      pdp
      carrierSlashOwnerTotalMa
      carrierSlashOwnerTotalMedsupp
      carrierSlashOwnerTotalPdp
    }
  }
`;

export const agentChangeRequest = gql`
  query agentChangeRequest($requestedChanges: RequestedChangesIn) {
    agentChangeRequest(requestedChanges: $requestedChanges)
  }
`;

export const sendContractRequest = gql`
  mutation sendContractRequest($info: contractRequestEmailInput!) {
    sendContractRequest(info: $info)
  }
`;

export const getCurrentProductionYear = gql`
  query getCurrentProductionYear {
    getCurrentProductionYear {
      currentproduction
    }
  }
`;

export const createContractRequest = gql`
  mutation createContractRequest($input: ContractRequestInput!) {
    createContractRequest(input: $input)
  }
`;

export const getStates = gql`
  query getStates {
    getStates {
      name
      code
    }
  }
`;

export const agentToh = gql`
  query agentToh($agentId: String) {
    agentToh(agentId: $agentId) {
      tohClass
      tohAgencyId
      tohEmail
      tohAgencies
      tohCc
    }
  }
`;

export const agentContactInfo = gql`
  query agentContactInfo($agentId: String) {
    agentContactInfo(agentId: $agentId) {
      agentId
      agentName
      mobilePhone
      workPhone
      phone
      email
      city
      county
      postalCode
      individualId
      state
      address1
      address2
      address3
    }
  }
`;

export const checkAgentNpn = gql`
  query checkAgentNpn($agentId: String, $npn: String) {
    checkAgentNpn(agentId: $agentId, npn: $npn)
  }
`;

export const updateAgentPassword = gql`
  mutation updateAgentPassword($options: AgentUserInput!) {
    updateAgentPassword(options: $options)
  }
`;

export const sendAwayEmailAws = gql`
  mutation sendAwayEmailAws($input: AWSSendEmailInput) {
    sendAwayEmailAws(input: $input) {
      success
    }
  }
`;

export const cognitoForgotPassword = gql`
  query cognitoForgotPassword($username: String!, $poolKey: String!) {
    cognitoForgotPassword(username: $username, poolKey: $poolKey)
  }
`;

export const cognitoConfirmPassword = gql`
  query cognitoConfirmPassword(
    $username: String!
    $verificationCode: String!
    $newPass: String!
    $poolKey: String!
  ) {
    cognitoConfirmPassword(
      username: $username
      verificationCode: $verificationCode
      newPass: $newPass
      poolKey: $poolKey
    )
  }
`;

export const updateLastLogin = gql`
  mutation updateLastLogin($id: String!) {
    updateLastLogin(id: $id)
  }
`;
//Onboarding
export const findCode = gql`
  query findCode($code: String!) {
    findCode(code: $code) {
      id
      recruiterName
      type
      recruiterEmail
      recruiterPhone
    }
  }
`;
export const findNpn = gql`
  query findNpn($npn: String!) {
    findNpn(npn: $npn) {
      agentName
    }
  }
`;
export const registerOnboardLead = gql`
  mutation registerOnboardLead($options: OnboardLeadInput) {
    registerOnboardLead(options: $options)
  }
`;

export const ocrByOnboardId = gql`
  query ocrByOnboardId($onboardId: String!) {
    ocrByOnboardId(onboardId: $onboardId) {
      carrierName
      status
      statesRequested
      ocrId
      createDate
    }
  }
`;

export const updateOnboardLead = gql`
  mutation updateOnboardLead($input: UpdateOnboardLeadInput) {
    updateOnboardLead(input: $input)
  }
`;

export const insertOcr = gql`
  mutation insertOcr($input: InsertOcrInput) {
    insertOcr(input: $input)
  }
`;

//End Onboarding

export const getCognitoUserAgent = gql`
  query getCognitoUserAgent($options: GetCognitoUserAgentArgs) {
    getCognitoUserAgent(options: $options) {
      Username
    }
  }
`;

export const getUserInfo = gql`
  query getUserInfo($loginType: String!, $id: String!) {
    getUserInfo(loginType: $loginType, id: $id) {
      id
      login
      firstName
      lastName
      agentWayEnabled
      direct
      deleted
      displayName
      createDate
      # lastModifiedDate
      # lastModifiedById
      mfaPhone
      loginDate
      agentName
      agentOwnerEmail
      email
      firstName
      lastName
      status
      displayName
      recruiter
      username
      agentgroup
      department
      agencyId
      agencyName
      agentId
      agentTotal
      phone
      onboardId
      recruiterId
      npn
      gender
      residentState
      birthDate
      ssn
      recruiterName
    }
  }
`;

export const isAgent = gql`
  query isAgent($id: String!) {
    isAgent(id: $id)
  }
`;

export const isAgentNow = gql`
  subscription isAgentNow($onboardId: String) {
    isAgentNow(onboardId: $onboardId)
  }
`;

export const getCalc = gql`
  query pdpCalc($eligibleDate: Date!, $enrollmentDate: Date!) {
    pdpCalc(eligibleDate: $eligibleDate, enrollmentDate: $enrollmentDate)
  }
`;
